import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import styles from './[contentId].module.scss'

import Seo from '@/components/general/seo'
import SpecificButton from '@/components/general/specificButton'
import Layout from '@/components/layout/layout'
import SectionTitle from '@/components/top/section-title'

import { ACCOUNT_ID } from '@/lib/constants'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="自動販売機や駅の自動改札機、銀行のATMなどを前にしたとき、どうしたら良いかわからなくなった経験、ありませんか？" />
      <div className={styles.wrap}>
        <div className={styles.wrapInner}>
          <div className={styles.answer}>
            <p className={styles.comment}>
              認知機能障害の中核症状「理解・判断力の低下」のサインかも
            </p>
          </div>
          <div className={styles.moreWrap}>
            <div className={styles.more}>
              <span className={styles.moreText}>詳しく解説</span>
            </div>
            <div className={styles.dotLine} />
          </div>
          <div className={styles.explanation}>
            <p>
              理解することに時間がかかるようになり、情報を処理する能力も低下して、一度に2つ以上のことを言われる、早口で言われると理解することが難しくなります。いつもとは違う出来事が起こると対応できず、混乱することがみられます。目に見えないものが理解しにくく、自動販売機や駅の自動改札機、銀行のATMなどを前にしたとき、どうしたら良いかわからなくなったり、あいまいな表現の理解・判断ができにくくなります。
            </p>
          </div>
          <SectionTitle theme="dark">Cognition Cafe Reserve</SectionTitle>
          <div className={styles.video}>
            <ReactPlayerLoader
              accountId={ACCOUNT_ID}
              videoId="6320900758112"
              playerId="hM1SBfBel"
              embedOptions={{ responsive: 'aspectRatio' }}
            />
            <p className={styles.caption}>
              大日本住友製薬（現：住友ファーマ株式会社）
            </p>
          </div>
          <SpecificButton videoId={2} />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={
              '自動販売機や駅の自動改札機、銀行のATMなどを前にしたとき、どうしたら良いかわからなくなった経験、ありませんか？'
            }
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
